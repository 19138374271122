<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
      :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'data'">
          <table style="border-bottom: none;">
            <tr>
              <td class="noBorder"><b>Nomor</b></td>
              <td class="noBorder"><b>:</b></td>
              <td class="noBorder"><b>{{ props.row.number }}</b></td>
            </tr>
            <tr v-for="val in props.row.data" v-bind:key="val.key">
              <td class="noBorder">{{ val.key }}</td>
              <td class="noBorder">:</td>
              <td class="noBorder">{{ val.value }}</td>
            </tr>
          </table>
        </span>
        <span v-else-if="props.column.field == 'status'">
          <span v-if="props.row.status == 'pending'" class="badge badge-warning text-white">Pending</span>
          <span v-if="props.row.status == 'process'" class="badge badge-primary">Process</span>
          <span v-if="props.row.status == 'complete'" class="badge badge-success">Complate</span>
          <span v-if="props.row.status == 'cancel'" class="badge badge-danger">Cancel</span>
        </span>
        <span v-else-if="props.column.field == 'action'">
          <div v-if="props.row.status == 'pending'">
            <router-link :to="'/cs-page/order/follow-up?id=' + props.row.id">
              <a class="btn btn-primary text-white margin5">Follow Up</a>
            </router-link>
            <a v-on:click="changeStatus(props.row.id, 'process')" class="btn btn-info text-white margin5">
              Tandai Diproses
            </a>
            <a v-on:click="changeStatus(props.row.id, 'cancel')" class="btn btn-danger text-white margin5">
              Batalkan
            </a>
          </div>
          <div v-else-if="props.row.status == 'process'">
            <a v-on:click="changeStatus(props.row.id, 'complete')" class="btn btn-success text-white margin5">
              Tandai Selesai
            </a>
            <a v-on:click="changeStatus(props.row.id, 'cancel')" class="btn btn-danger text-white margin5">
              Batalkan
            </a>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [{
              label: "Data",
              field: "data",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "60%",
            },
            {
              label: "Status",
              field: "status",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "20%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [{
              field: "",
              type: "",
            }, ],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      async deleteItem(id) {
        try {
          let response = await this.$store.dispatch("order_delete", {
            id: id
          });
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      async changeStatus(id, status) {
        try {
          let response = await this.$store.dispatch("order_change_status", {
            id,
            status
          });
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign({},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({
          page: params.currentPage
        });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({
          limit: params.currentPerPage
        });
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.table.columns[params.columnIndex].field,
          }, ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          for (let i = 0; i < response.data.data.orders.length; i++) {
            response.data.data.orders[i].data = JSON.parse(response.data.data.orders[i].data)
          }
          this.table.rows = response.data.data.orders;
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        return await this.$store.dispatch("order_list", param);
      },
    },
  };
</script>

<style scoped>
  .noBorder {
    border: none !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .margin5 {
    margin: 5px;
  }
</style>