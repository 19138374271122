<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Web Whatsapp</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4" v-for="d in device" v-bind:key="d.id">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h3>{{ d.name }}
                                <span v-if="d.status == 'connected'" class="badge badge-success">Terkoneksi</span>
                                <span v-else class="badge badge-danger">Tidak Terkoneksi</span>
                            </h3>
                            <hr style="margin-top: 15px;margin-bottom: 15px;">
                            <router-link :to="'/web-whatsapp/' + d.id" class="btn btn-success btn-block">
                                Akses Whatsapp Web
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            device: {}
        };
      },
      mounted() {
        this.getDevice()
      },
      methods: {
        async getDevice() {
            let res = await this.$store.dispatch("device_list_all");
            this.device = res.data.data.devices
        },
      },
    };
</script>